<template>
    <div class="news">
      <h1>Latest News</h1>
      <p>Stay updated with the latest Pokémon card news and events.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'News'
  }
  </script>
  
  <style scoped>
  .news {
    padding: 20px;
  }
  </style>